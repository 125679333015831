import { FIESTAS } from 'constants/fiestas';
import { getUrlWithParams, pages } from 'services/routing';
import { DateUtils } from './date-utils';

export enum Campaings {
  CHRISTMAS_2021 = 'christmas2021',
  REWIND = 'rewind2023',
  VALENTINES_2023 = 'valentines2023',
  COUPONS_FIESTA = 'couponsFiesta',
  PROMOTION_NO_FEES = 'promotionNoFees',
  PROMOTION_MARATHON = 'promotionMarathon',
  GIVE_BACK_FRIDAY = 'givebackfriday2022',
  SUB_90_PROMO = 'sub90Promo202306',
  CHRISTMAS = 'christmas2023',
  TICKETS_CAMPAIGN = 'HALLOWEEN2024',
  BATTLE_PASS_CAMPAIGN = 'summerFiesta202406',
  HALLOWEEN = 'HALLOWEEN2024',
  GIVEAWAY = 'GIVEAWAY',
}

export class CampaingActive {
  private static readonly validator: Record<Campaings, () => boolean> = {
    [Campaings.CHRISTMAS_2021]: CampaingActive.christmas2021,
    [Campaings.REWIND]: CampaingActive.rewind,
    [Campaings.VALENTINES_2023]: CampaingActive.valentines2023,
    [Campaings.COUPONS_FIESTA]: CampaingActive.couponsFiesta,
    [Campaings.PROMOTION_NO_FEES]: CampaingActive.promotionNoFees,
    [Campaings.PROMOTION_MARATHON]: CampaingActive.promotionMarathon,
    [Campaings.HALLOWEEN]: CampaingActive.halloween,
    [Campaings.GIVE_BACK_FRIDAY]: CampaingActive.giveBackFriday,
    [Campaings.CHRISTMAS]: CampaingActive.christmas,
    [Campaings.SUB_90_PROMO]: CampaingActive.subPromo,
    [Campaings.TICKETS_CAMPAIGN]: CampaingActive.isTicketsCampaignActive,
    [Campaings.BATTLE_PASS_CAMPAIGN]: CampaingActive.isBattlepassCampaignActive,
    [Campaings.GIVEAWAY]: CampaingActive.giveaway,
  };

  static check(campaingName: Campaings): boolean {
    if (!(campaingName in CampaingActive.validator)) {
      return false;
    }

    return CampaingActive.validator[campaingName]();
  }

  public static isTicketsCampaignActive(): boolean {
    return DateUtils.isBetween({ start: FIESTAS.TICKETS_CAMPAIGN.startDate, end: FIESTAS.TICKETS_CAMPAIGN.endDate });
  }

  public static isBattlepassCampaignActive(): boolean {
    return DateUtils.isBetween({
      start: FIESTAS.BATTLE_PASS_CAMPAIGN.startDate,
      end: FIESTAS.BATTLE_PASS_CAMPAIGN.endDate,
    });
  }

  private static christmas2021() {
    return DateUtils.isBetween({
      start: FIESTAS.CHRISTMAS_2021.startDate,
      end: FIESTAS.CHRISTMAS_2021.endDate,
    });
  }

  private static subPromo() {
    return DateUtils.isBetween({
      start: FIESTAS.SUB_PROMO.startDate,
      end: FIESTAS.SUB_PROMO.endDate,
    });
  }

  private static halloween() {
    return DateUtils.isBetween({
      start: FIESTAS.HALLOWEEN.startDate,
      end: FIESTAS.HALLOWEEN.endDate,
    });
  }

  private static christmas() {
    return DateUtils.isBetween({
      start: FIESTAS.CHRISTMAS.startDate,
      end: FIESTAS.CHRISTMAS.endDate,
    });
  }

  private static giveBackFriday() {
    return DateUtils.isBetween({
      start: FIESTAS.BLACK_FRIDAY_2022.startDate,
      end: FIESTAS.BLACK_FRIDAY_2022.endDate,
    });
  }

  private static rewind() {
    return DateUtils.isBetween({
      start: FIESTAS.REWIND.startDate,
      end: FIESTAS.REWIND.endDate,
    });
  }

  private static valentines2023() {
    return DateUtils.isBetween({
      start: FIESTAS.VALENTINES_2023.startDate,
      end: FIESTAS.VALENTINES_2023.endDate,
    });
  }

  private static giveaway() {
    return DateUtils.isBetween({
      start: FIESTAS.GIVEAWAY.startDate,
      end: FIESTAS.GIVEAWAY.endDate,
    });
  }

  private static couponsFiesta() {
    return DateUtils.isBetween({
      start: FIESTAS.COUPONS_FIESTA.startDate,
      end: FIESTAS.COUPONS_FIESTA.endDate,
    });
  }

  private static promotionNoFees() {
    return DateUtils.isBetween({
      start: FIESTAS.PROMOTION_NO_FEES_GLOBAL.startDate,
      end: FIESTAS.PROMOTION_NO_FEES_GLOBAL.endDate,
    });
  }

  private static promotionMarathon() {
    return DateUtils.isBetween({
      start: FIESTAS.PROMOTION_MARATHON.startDate,
      end: FIESTAS.PROMOTION_MARATHON.endDate,
    });
  }

  public static isValentinesAssetsVisible(): boolean {
    const currentYear = new Date().getFullYear();
    const start = `${currentYear}-01-29T07:00:00.000Z`;
    const end = `${currentYear}-02-16T07:00:00.000Z`;

    return DateUtils.isBetween({ start, end });
  }

  public static isHalloweenAssetsVisible(): boolean {
    const currentYear = new Date().getFullYear();
    const start = `${currentYear}-09-20T07:00:00.000Z`;
    const end = `${currentYear}-11-01T20:00:00.000Z`;

    return DateUtils.isBetween({ start, end });
  }

  public static isChristmasAssetsVisible(): boolean {
    const currentYear = new Date().getFullYear();
    const start = `${currentYear}-11-15T07:00:00.000Z`;
    const end = `${currentYear + 1}-01-07T20:00:00.000Z`;

    return DateUtils.isBetween({ start, end });
  }

  static getLocationEvent(pathname: string, slug: string): string {
    const dashboard = getUrlWithParams(pages.DASHBOARD, { slug });

    if (dashboard === pathname) {
      return 'Streamer Dashboard';
    }

    const publicPage = getUrlWithParams(pages.USER_PAGE, { slug });

    if (publicPage === pathname) {
      return 'Streamer public page';
    }

    const streamers = pages.STREAMERS;

    if (streamers === pathname) {
      return 'My streamers';
    }

    return 'Streamers';
  }
}
