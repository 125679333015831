import { HALLOWEEN, CHRISTMAS } from 'constants/images';
import ClassNames from '@streamloots/classnames';
import theme from './campaign-tickets.scss';

const classNames = ClassNames(theme);

function getPromoResources(relativeUrl: string): string {
  // return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/summerFiesta202306/${relativeUrl}`;
  return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/halloween/${relativeUrl}`;
  // return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/christmas/${relativeUrl}`;
}

interface TicketCampaignConstants {
  LOGO_EN?: string;
  LOGO_ES?: string;
  CHIPI_IMAGE?: string;
  CHIPI_IMAGE_ES?: string;
  MENU_LOGO: string;
  CHIPI_VIDEO?: string;
  BG: string;
  TICKET_IMAGE: string;
  CHIPI_BG_CLASS?: string;
  SPONSORS?: { imgUrl: string; tooltipKey?: string; url: string }[];
}

const halloweenInfo: TicketCampaignConstants = {
  LOGO_EN: getPromoResources('Logo-EN-min.png'),
  LOGO_ES: getPromoResources('Logo-ES-min.png'),
  CHIPI_IMAGE: getPromoResources('Logo-EN-min.png'),
  CHIPI_IMAGE_ES: getPromoResources('Logo-ES-min.png'),
  MENU_LOGO: getPromoResources('logo-menu.png'),
  // CHIPI_VIDEO: HALLOWEEN.SCREAMY_VIDEO,
  BG: HALLOWEEN.PANEL_BG,
  TICKET_IMAGE: HALLOWEEN.CANDY,
  CHIPI_BG_CLASS: classNames('halloween-video-bg'),
  SPONSORS: [
    {
      imgUrl:
        'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/halloween/OBSBOT-LogoWhite.png',
      url: 'https://bit.ly/3TYq9ky',
    },
    {
      imgUrl:
        'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/halloween/Pampling-WHITE.png',
      url: 'https://bit.ly/pmpstreamloots',
    },
  ],
};

const christmas: TicketCampaignConstants = {
  LOGO_EN: getPromoResources('Logo-EN-min.png'),
  LOGO_ES: getPromoResources('Logo-ES-min.png'),
  CHIPI_IMAGE: getPromoResources('Logo-EN-min.png'),
  CHIPI_IMAGE_ES: getPromoResources('Logo-ES-min.png'),
  MENU_LOGO: getPromoResources('streamy_claus_logo-min.png'),
  // CHIPI_VIDEO: HALLOWEEN.SCREAMY_VIDEO,
  BG: CHRISTMAS.DASHBOARD_PANEL_BG,
  TICKET_IMAGE: CHRISTMAS.SNOWFLAKE,
  CHIPI_BG_CLASS: classNames('halloween-video-bg'),
  // SPONSORS: [
  //   {
  //     tooltipKey: 'campaings:ticketsCampaign.sponsorPampling',
  //     imgUrl:
  //       'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/halloween/Pampling-WHITE.png',
  //   },
  // ],
};

const SUMMER_FIESTA: TicketCampaignConstants = {
  CHIPI_IMAGE: getPromoResources('Chipi-EN-min.png'),
  MENU_LOGO: getPromoResources('logo-menu.png'),
  CHIPI_IMAGE_ES: getPromoResources('Chipi-ES-min.png'),
  BG: getPromoResources('Background-min.jpg'),
  // TICKET_IMAGE: 'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/ticket.svg',
  TICKET_IMAGE:
    'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/summerFiesta202306/H4-min.png',
};

export const TICKETS_CAMPAIGN_RESOURCES: TicketCampaignConstants = halloweenInfo;
